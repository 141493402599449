<template>
  <div id="appCapsule">
    <div class="section">
      <div class="d-flex justify-content-center trade-head-txt pt-3">
        <ion-icon class="text-success" name="trending-up-outline"></ion-icon> &nbsp;
        <h5>EarnRelai Auto Stake</h5>
      </div>
      <div>
        <p>
          Stake your crypto to earn interest for a certain period of time
        </p>
        <p>
          There are some few pointers to take note of
        </p>
        <ul>
          <li>
            After the estimated APY have been accumulated, you can withdraw the available funds to your wallet
          </li>
          <li>
            When a new deposit is detected, both the interest rate and earning amount will be adjusted accordingly.
          </li>
        </ul>
        <p>
          To get started select one of the available plans below
        </p>
        <!--               <div class="alert alert-outline-primary mb-3">-->
        <!--                  Note that withdrawals will be disabled for the staked crypto until the staking period have been fulfilled-->
        <!--               </div>-->
        <!--               <hr>-->
        <!--               <h5>Interest per available balance <small class="text-muted">(currency in USD)</small></h5>-->
        <div class="row">
          <ul class="listview image-listview inset mx-0">
            <li v-for="(trade, idx) in trades" :key="'trade_'+idx">
              <a href="javascript:void(0)" class="item">
                <div class="in">
                  <div>
                    <header>Invest</header>
                    {{ trade.min_amt_btc.toLocaleString() }} BTC - {{ trade.max_amt_btc.toLocaleString() }} BTC
                    <footer>
                      Estimated APY of {{ trade.percentage_val }}% in {{ trade.duration }} days <br>
                      <strong class="text-primary">
                        Additional {{ trade.e_percentage_val }}% daily for {{ trade.e_duration }} days
                      </strong>
                    </footer>
                  </div>
                  <span class="text-primary">
                                       <button class="btn btn-outline-primary" style="padding-right: 5px"
                                               @click.prevent="enableTrade(trade)">
                                           Select <ion-icon name="chevron-forward-outline"></ion-icon>
                                       </button>
                                   </span>
                </div>
              </a>
            </li>
          </ul>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import {mapGetters} from 'vuex'

export default {
  name: "autoTrade",
  data() {
    return {
      semaphore: 1,
      trades: [
        {
          min_amt: 1,
          max_amt: 499,
          duration: 14,
          percentage_val: 50,
          e_percentage_val: 1, // div by 100
          e_duration: 90,
          min_amt_btc: 0.5,
          max_amt_btc: 1
        },
        {
          min_amt: 500,
          max_amt: 9999,
          duration: 20,
          percentage_val: 50,
          e_percentage_val: 1, // div by 100
          e_duration: 150,
          min_amt_btc: 1,
          max_amt_btc: 5
        },
        {
          min_amt: 10000,
          max_amt: 49999,
          duration: 30,
          percentage_val: 50,
          e_percentage_val: 2, // div by 100
          e_duration: 150,
          min_amt_btc: 5,
          max_amt_btc: 10
        },
        {
          min_amt: 50000,
          max_amt: 250000,
          duration: 60,
          percentage_val: 150,
          e_percentage_val: 2, // div by 100
          e_duration: 200,
          min_amt_btc: 10,
          max_amt_btc: 100
        }
      ]
    }
  },
  methods: {
    async enableTrade(trade) {
      if (this.semaphore === 1) {
        this.semaphore = 0;
        const {cryptoId} = this.$route.params;
        let user = await this.$store.dispatch('user/getUser', this.$store.getters['user/getUser'].id);
        if (user.status) {
          if (!user.data.data.assets[cryptoId]) {
            this.semaphore = 1;
            return this.$store.dispatch('notification/display_noti', {
              message: `Credit your ${cryptoId} asset to enable this feature`,
              timeout: 1500
            });
          } else if (user.data.data.assets[cryptoId] <= 0) {
            this.semaphore = 1;
            return this.$store.dispatch('notification/display_noti', {
              message: `Credit your ${cryptoId} asset to enable this feature`,
              timeout: 1500
            });
          }
        } else {
          this.semaphore = 1;
          return this.$store.dispatch('notification/display_noti', {
            message: `An unknown error occurred!`,
            timeout: 1500
          });
        }
        return this.$store.dispatch('notification/display_noti', {
          message: "Proceed with this selection?",
          context: 'icon',
          buttons: [
            {
              name: 'Yes proceed',
              callback: async () => {
                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                this.$loader.show();
                let response = await this.$store.dispatch('user/enableAutoTrade', {
                  cryptoId,
                  duration: trade.duration,
                  e_duration: (trade.e_duration - trade.duration),
                  e_percentage_val: (trade.e_percentage_val / 100),
                  percentage_val: (trade.percentage_val / 100)
                });
                this.$loader.hide();
                this.semaphore = 1;
                if (response.status) {
                  await this.$store.dispatch('notification/display_noti', {
                    message: 'Operation successful!',
                    timeout: 1500
                  });
                  return this.$router.push({name: 'MetricsWallet'});
                } else {
                  return this.$store.dispatch('notification/display_noti', {
                    message: response.message,
                  });
                }
              }
            },
            {
              name: 'Cancel',
              callback: () => {
                this.semaphore = 1;
                this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
              }
            }
          ]
        });
      }

    }
  },
}
</script>

<style scoped>
.trade-head-txt,
.trade-head-txt h5 {
  font-size: 1em
}

.image-listview > li a.item:after {
  content: none;
}

.image-listview > li a.item {
  padding-right: 10px;
}
</style>